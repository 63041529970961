import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import ReduceAll from "./reduces";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  ReduceAll,

  composeEnhancers(applyMiddleware(thunk))
);

export default store;