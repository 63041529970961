const initialState = {
  dateRange: [
    new Date(new Date().setDate(new Date().getDate() - 31)),
    new Date(),
  ],
};

const tableOwe = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_TABLE_OWE":
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default tableOwe;

export const setTableOwe = (payload) => {
  // ex:  payload = {dateRange: 1000}
  return {
    type: "SET_TABLE_OWE",
    payload: payload,
  };
};
