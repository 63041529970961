import { combineReducers } from "redux";
import table1311 from "src/redux_state/Table1311.js";
import table3311 from "src/redux_state/Table3311.js";
import tableOwe from "src/redux_state/TableOwe.js";
import changeState from "./reduceChangeState.js";

const ReduceAll = combineReducers({
  changeState: changeState,
  tableOwe: tableOwe,
  table1311: table1311,
  table3311: table3311,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return ReduceAll(state, action);
};

export default rootReducer;
