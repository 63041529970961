const initialState = {
    dateRange: [
      new Date(new Date().setDate(new Date().getDate() - 31)),
      new Date(),
    ],
  };
  
  const table3311 = (state = initialState, { type, payload }) => {
    switch (type) {
      case "SET_TABLE_3311":
        return { ...state, ...payload };
      default:
        return state;
    }
  };
  
  export default table3311;
  
  export const setTable3311= (payload) => {
    // ex:  payload = {dateRange: 1000}
    return {
      type: "SET_TABLE_3311",
      payload: payload,
    };
  };
  